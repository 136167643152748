<template>
  <el-card class="iot-person public-page-style iot-search-header">
    <div slot="header">
        <div class="header">
          <el-button
        type="primary"
        plain
        size="mini"
        class="iot-account-create"
        @click="addConfigurationImgTypePage"
        v-if="$store.state.permissionArr.indexOf('user:add') > -1"
        >新增</el-button
      >  
        </div>
      
    </div>
    <div class="tab" style="height: 690px; overflow-y: scroll">
      <el-table :data="tableData"
      stripe
      border=""
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }">
        <el-table-column
          prop="imgTypeId"
          width="115"
          label="图片类型Id"
        ></el-table-column>
        <el-table-column prop="typeName" label="图片类型"></el-table-column>
        <el-table-column prop="orderNum" label="排序"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="editConfigurationImgTypePage(scope.row.imgTypeId)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              plain
              @click="delConfigurationImgTypePage(scope.row.imgTypeId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: right">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="getForm.current"
        :page-sizes="[10, 20, 30, 40]"
        :total="total"
        :page-size="getForm.size"
        style=""
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getConfigurationImgTypePage();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getConfigurationImgTypePage();
          }
        "
      >
      </el-pagination>
    </div>

    <el-dialog
    top="3%"
      :show-close="false"
      :visible.sync="accountDialogVisinbile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      width="30%"
      class="iot-account"
    >
      <div slot="title" style="">
        <img
          style="width: 25px; vertical-align: middle; margin-top: -5px"
          src="../assets/pencil.png"
        />
        <div style="margin-left: 7px; padding-top: 15px; display: inline-block">
          {{ formTitle }}
        </div>
      </div>
      <el-form
        :model="configurationImgTypeForm"
        ref="userRef"
        label-width="100px"
        style="width: calc(100% - 60px)"
      >
        <el-form-item label="类型名">
          <el-input
            v-model="configurationImgTypeForm.typeName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="configurationImgTypeForm.orderNum"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain size="mini" @click="submitForm()"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="accountDialogVisinbile = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
let that;
export default {
  data() {
    return {
    theme: { TABLE_HEADER_COLOR },
      formTitle: "",
      accountDialogVisinbile: false,
      configurationImgTypeForm: {
        typeName: "",
        orderNum: 1,
      },
      tableData: [],
      total: 0,
      // 分页
      getForm: {
        username: "",
        mobile: "",
        size: 10,
        current: 1,
      },
    };
  },
  methods: {
    //分页方法
    handleSizeChange(val) {
      that.getForm.size = val;
      that.getConfigurationImgTypePage();
    },
    handleCurrentChange(val) {
      that.getForm.current = val;
      that.getConfigurationImgTypePage();
    },

    getConfigurationImgTypePage() {
      that.$api.configurationImgType
        .getConfigurationImgTypePage(that.getForm)
        .then((res) => {
          if (res.code == 200) {
            that.tableData = res.data.records;
            that.total=res.data.total;
          }
        });
    },

    addConfigurationImgTypePage() {
      that.formTitle = "新增图片类型";
      that.accountDialogVisinbile = true;
     
      that.configurationImgTypeForm = {
        typeName: "",
        orderNum: that.total+1,
      };
    },
    editConfigurationImgTypePage(imgTypeId) {
      that.formTitle = "修改图片类型";
      that.$api.configurationImgType
        .getConfigurationImgType(imgTypeId)
        .then((res) => {
          if (res.code == 200) {
            that.configurationImgTypeForm = res.data;
            that.accountDialogVisinbile = true;
          }
        });
    },
    delConfigurationImgTypePage(imgTypeId) {
      that
        .$confirm("确定要删除么！", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.configurationImgType
            .delConfigurationImgType(imgTypeId)
            .then((res) => {
              if (res.code == 200) {
                  that.getConfigurationImgTypePage();
              }
            });
        });
    },
    submitForm() {
      let configurationImgType = that.configurationImgTypeForm.imgTypeId
        ? that.$api.configurationImgType.putConfigurationImgType(
            that.configurationImgTypeForm
          )
        : that.$api.configurationImgType.postConfigurationImgType(
            that.configurationImgTypeForm
          );
      configurationImgType.then((res) => {
        if (res.code == 200) {
          console.log(res);
          that.getConfigurationImgTypePage();
          that.accountDialogVisinbile = false;
        }
      });
    },
  },
  created() {
    that = this;
  },
  mounted() {
    that.getConfigurationImgTypePage();
  },
};
</script>

<style scoped>
.header{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
</style>