var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"iot-person public-page-style iot-search-header"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"header"},[(_vm.$store.state.permissionArr.indexOf('user:add') > -1)?_c('el-button',{staticClass:"iot-account-create",attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":_vm.addConfigurationImgTypePage}},[_vm._v("新增")]):_vm._e()],1)]),_c('div',{staticClass:"tab",staticStyle:{"height":"690px","overflow-y":"scroll"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":"","border":"","header-cell-style":{ background: _vm.theme.TABLE_HEADER_COLOR }}},[_c('el-table-column',{attrs:{"prop":"imgTypeId","width":"115","label":"图片类型Id"}}),_c('el-table-column',{attrs:{"prop":"typeName","label":"图片类型"}}),_c('el-table-column',{attrs:{"prop":"orderNum","label":"排序"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"primary","plain":""},on:{"click":function($event){return _vm.editConfigurationImgTypePage(scope.row.imgTypeId)}}},[_vm._v("修改")]),_c('el-button',{attrs:{"size":"mini","type":"danger","plain":""},on:{"click":function($event){return _vm.delConfigurationImgTypePage(scope.row.imgTypeId)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"right"}},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.getForm.current,"page-sizes":[10, 20, 30, 40],"total":_vm.total,"page-size":_vm.getForm.size,"layout":"total, sizes, prev, pager, next, jumper"},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"prev-click":() => {
          _vm.$set(_vm.getForm, 'current', _vm.getForm.current - 1);
          _vm.getConfigurationImgTypePage();
        },"next-click":() => {
          _vm.$set(_vm.getForm, 'current', _vm.getForm.current + 1);
          _vm.getConfigurationImgTypePage();
        }}})],1),_c('el-dialog',{staticClass:"iot-account",attrs:{"top":"3%","show-close":false,"visible":_vm.accountDialogVisinbile,"destroy-on-close":true,"close-on-press-escape":true,"close-on-click-modal":false,"width":"30%"},on:{"update:visible":function($event){_vm.accountDialogVisinbile=$event}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('img',{staticStyle:{"width":"25px","vertical-align":"middle","margin-top":"-5px"},attrs:{"src":require("../assets/pencil.png")}}),_c('div',{staticStyle:{"margin-left":"7px","padding-top":"15px","display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('el-form',{ref:"userRef",staticStyle:{"width":"calc(100% - 60px)"},attrs:{"model":_vm.configurationImgTypeForm,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"类型名"}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.configurationImgTypeForm.typeName),callback:function ($$v) {_vm.$set(_vm.configurationImgTypeForm, "typeName", $$v)},expression:"configurationImgTypeForm.typeName"}})],1),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.configurationImgTypeForm.orderNum),callback:function ($$v) {_vm.$set(_vm.configurationImgTypeForm, "orderNum", $$v)},expression:"configurationImgTypeForm.orderNum"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("确 定")]),_c('el-button',{attrs:{"type":"info","plain":"","size":"mini"},on:{"click":function($event){_vm.accountDialogVisinbile = false}}},[_vm._v("取 消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }